<template>
    <div class="row">
        <div class="col-12">
            <form @submit.prevent="postData" class="row justify-content-center">
                <div class="form-group col-12">
                    <label for="">File</label>
                    <select class="form-control" v-model="sla.type">
                        <option value="1">URL</option>
                        <option value="2">Upload File</option>
                    </select>
                </div>
                <div v-if="sla.type == 1" class="form-group col-12">
                    <label for="">Input URL</label>
                    <input type="url" v-model="sla.path" class="form-control">
                </div>
                <div v-if="sla.type == 2" class="form-group col-12">
                    <label for="">Upload File </label><br>
                    <file-upload
                    class="btn btn-lg btn-current mr-1"
                    @input="updateFileSLA"
                    @input-file="inputFileSLA"
                    @input-filter="fileFilterSLA"
                    :custom-action="fileActionSLA"
                    accept="video/mp4"
                    ref="sla" required>
                    <i class="fa fa-plus"></i>
                    Upload Video
                    </file-upload>

                    <a v-if="sla.path" :href="sla.type == 2 ?  sla.path : sla.path" target="_blank" class="btn btn-lg btn-success">Current File</a>

                    <button type="button" class="btn btn-lg btn-danger ml-1"  v-if="!$refs.sla || $refs.sla.active" @click.prevent="$refs.sla.active = false">
                    <i class="fa fa-stop" aria-hidden="true"></i>
                    Stop Upload
                    </button>
                </div>
                <div class="form-group col-12">
                    <label for="">Description</label>
                    <textarea rows="5" v-model="sla.description" class="form-control" required></textarea>
                </div>
                <div class="form-group col-12 text-right">
                    <button type="submit" class="btn btn-current">Submit</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import FileUpload from 'vue-upload-component'
export default {
    components:{
        FileUpload
    },
    data(){
        return{
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            files_sla: [],
            sla: {
                id: '',
                type: 1,
                path: '',
                description: '',
            }
        }
    },
    mounted(){
        this.getSLA()
        
    },
    methods: {
        async getSLA(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/sla`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.data){
                    this.sla = res.data.data
                }
                this.isLoad = false
            })
        },
        async postData(){
            var data = {
                id : this.sla.id,
                type : this.sla.type,
                path : this.sla.path,
                description : this.sla.description
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/sla`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.getSLA()
                if(res.data.success) {
                    this.$swal({
                        toast: true,
                        title: 'SLA',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'SLA',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        updateFileSLA(value) {
            this.files_sla = value
        },
        async fileActionSLA(file) {
            return this.postFile(file)
        },
        async inputFileSLA(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.sla.active) {
                    this.$refs.sla.active = true
                }
            }
        },
        fileFilterSLA(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(mp4)$/i.test(newFile.name)) {
                    return prevent()
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir',  'video');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.sla.path = res.data.path
                return res.data
            })
        },
        async deleteFile(file){
            let data = new FormData();
            data.append('path', file.response.path);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files_sla = []
                this.sla.path = ''
                return res.data
            })
        },
    }
    
}
</script>