<template>
    <div class="row py-3">
        <div class="col-12 mb-4">
            <div id="accordion2" class="accordion mb-0">
                <div class="card shadow-xss mb-0">
                    <div class="card-header bg-greylight theme-dark-bg" id="heading2">
                        <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2"> Explanation </button></h5>
                    </div>
                    <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordion2">
                        <form @submit.prevent="postDataExp" class="card-body row">
                            <div class="form-group col-12">
                                <label for="">File</label>
                                <select class="form-control" v-model="exp.type">
                                    <option value="1">URL</option>
                                    <option value="2">Upload File</option>
                                </select>
                            </div>
                            <div v-if="exp.type == 1" class="form-group col-12">
                                <label for="">Input URL</label>
                                <input type="url" v-model="exp.path" class="form-control">
                            </div>
                            <div v-else-if="exp.type == 2" class="form-group col-12">
                                <label for="">Upload File</label><br>
                                <file-upload
                                class="btn btn-lg btn-current mr-1"
                                @input="updateFileCEFR"
                                @input-file="inputFileCEFR"
                                @input-filter="fileFilterCEFR"
                                :custom-action="fileActionCEFR"
                                accept="video/mp4"
                                ref="tye" required>
                                <i class="fa fa-plus"></i>
                                Upload Video
                                </file-upload>

                                <a v-if="exp.path" :href="exp.type == 2 ?  exp.path : exp.path" target="_blank" class="btn btn-lg btn-success">Current File</a>

                                <a href="javascript:void(0)" class="btn btn-lg btn-danger ml-1" v-if="!$refs.tye || $refs.tye.active" @click.prevent="$refs.tye.active = false">
                                <i class="fa fa-stop" aria-hidden="true"></i>
                                Stop Upload
                                </a>
                            </div>
                            <div class="form-group col-12">
                                <label for="">Description</label>
                                <textarea v-model="exp.description" rows="3" class="form-control" required></textarea>
                            </div>
                            <div class="form-group col-12 text-right">
                                <input type="submit" class="btn btn-current" value="Submit">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-right mb-3">
            <router-link :to="{name: 'SettingsAdminCreatePT'}" class="btn btn-current btn-lg"><i class="fas fa-plus mr-2"></i>New Diagnostic Test</router-link>
        </div>
        <div  class="col-12 mb-3" v-if="pt.length">
            <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
        </div>
        <div class="col-12">
            <div class="row" v-if="!isLoad">
                <div class="col-12 text-center" v-if="pt.length">
                    <div class="table-responsive mw-100 px-1">
                        <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="pt" :page="1" :perPage="10" :waitForPager="true">
                            <template name="default" slot-scope="{ row, index }">
                                <tr>
                                    <td>{{ index+1 }}</td>
                                    <td>{{ row.title }}</td>
                                    <td>
                                        <router-link class="btn btn-sm btn-primary mr-2" :to="{name: 'SettingsAdminSubmissionPT', params: {idPT: row.id}}">{{row.submission}}</router-link>
                                    </td>
                                    <td>{{ row.created ? row.created.name : '' }}</td>
                                    <td>{{ row.time ? row.time + ' Minutes' : '' }}</td>
                                    <td>
                                        <button @click="changeStatusAlert(row.id, !row.publish)" class="btn btn-sm" :class="{'btn-success' : row.publish, 'btn-danger' : !row.publish }"><i class="fas" :class="{'fa-check' : row.publish, 'fa-times' : !row.publish }"></i></button>
                                    </td>
                                    <td>
                                        <router-link class=" mx-2" :to="{name: 'SettingsAdminDetailPT', params: {idPT: row.id}}"><i class="fas fa-info text-info"></i></router-link>
                                        <router-link class=" mx-2" :to="{name: 'SettingsAdminEditPT', params: {idPT: row.id}}"><i class="fas fa-edit text-warning"></i></router-link>
                                        <a href="javascript:void(0)" @click="deleteAlert(row.id)" class="mx-2"><i class="fas fa-trash text-danger"></i></a>
                                    </td>
                                </tr>
                            </template>
                            <template name="no-result">
                                Nothing to see here
                            </template>
                        </datatable>
                    </div>
                    <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                </div>
                <div v-else class="col-12">
                    <div class="card bg-grey border-0">
                        <div class="card-body text-center py-5">
                            <img :src="'/images/empty.png'" alt="" width="300">
                            <h1 class="text-muted">Data don't exist</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import FileUpload from 'vue-upload-component'
export default {
    components:{
        FileUpload
    },
    data(){
        return{
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            files: [],
            pt: [],
            exp : {
                id: '',
                type: 1,
                path: '',
                description: ''
            },
            tableFilter: '',
            page: 1,
            columns: [
                {label: 'No', field: 'id',headerClass: 'border-0 bg-current text-white py-3', class: 'product-thumbnail text-center', filterable: false, sortable: false},
                {label: 'Title', field: 'title', headerClass: 'border-0 bg-current text-white py-3', class: 'product-thumbnail text-center wide-column'},
                {label: 'Submission', field: 'submission', headerClass: 'border-0 bg-current text-white py-3', class: 'product-thumbnail text-center wide-column'},
                {label: 'Created By', field: 'created_by.name', headerClass: 'border-0 bg-current text-white py-3', class: 'product-p text-center'},
                {label: 'Time', field: 'time', headerClass: 'border-0 bg-current text-white py-3', class: 'product-p text-center'},
                {label: 'Publish', field: 'publish', headerClass: 'border-0 bg-current text-white py-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Option', headerClass: 'border-0 bg-current text-white py-3', class: 'product-remove text-center', filterable: false, sortable: false},
            ],
        }
    },
    created(){
        this.getData()
    },
    methods: {
        async getData(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/pt`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.pt = res.data.data
                if (res.data.exp) {
                    this.exp = res.data.exp
                }
                this.isLoad = false
            })
        },
        changeStatusAlert(id, status){
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Change Status it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        id: id,
                        status: status
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/pt/change-status`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Diagnostic Test!',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getData()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Diagnostic Test!',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deletePt(id);
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'TYE',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deletePt(id){
            var data = {
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/pt`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.getData()
            }).catch(() => {
                this.getData()
            })
        },
        async postDataExp(){
            var data = {
                id : this.exp.id,
                description : this.exp.description,
                type : this.exp.type,
                path : this.exp.path,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/pt/exp`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.getData()
                    this.$swal({
                        toast: true,
                        title: 'Diagnostic Test Exp',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Diagnostic Test Exp',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        updateFileCEFR(value) {
            this.files = value
        },
        async fileActionCEFR(file) {
            return this.postFile(file)
        },
        async inputFileCEFR(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.tye.active) {
                    this.$refs.tye.active = true
                }
            }
        },
        fileFilterCEFR(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(mp4)$/i.test(newFile.name)) {
                    return prevent()
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir',  'video');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.exp.path = res.data.path
                return res.data
            })
        },
        async deleteFile(file){
            let data = new FormData();
            data.append('path', file.response.path);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files = []
                this.exp.path = ''
                return res.data
            })
        },
    }
    
}
</script>