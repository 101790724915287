<template>
    <div class="row p-0">
        <div v-if="!isLoad" class="col-12 text-center py-3">
            <div class="row">
                <div class="col-12 mb-4">
                    <div id="accordion1" class="accordion mb-0">
                        <div class="card  mb-0">
                            <div class="card-header bg-greylight theme-dark-bg" id="heading1">
                                <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link " data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">Create new Level Overview</button></h5>
                            </div>
                            <div id="collapse1" class="collapse show" aria-labelledby="heading1" data-parent="#accordion1">
                                <form @submit.prevent="postLevel" class="row p-3">
                                    <div class="form-group col-12">
                                        <input type="text" v-model="form.level" class="form-control" placeholder="Insert Level" >
                                    </div>
                                    <div class="form-group col-12">
                                        <input type="text" v-model="form.final_remarks" class="form-control" placeholder="Insert Final Remarks" >
                                    </div>
                                    <div class="form-group col-12">
                                        <input type="text" v-model="form.meaning" class="form-control" placeholder="Insert Meaning" >
                                    </div>
                                    <div class="col-2">
                                        <input type="submit" class="btn btn-current btn-lg btn-block" value="Submit" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
                </div>
                <div class="col-12">
                    <div class="table-responsive mw-100 px-1">
                        <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="data" :page="1" :perPage="10">
                            <template name="default" slot-scope="{ row, index }">
                                <tr v-if="row">
                                    <td>{{ index+1 }}</td>
                                    <td>
                                        <p>{{ row.level }}</p>
                                    </td>
                                    <td>
                                        <p>{{ row.final_remarks }}</p>
                                    </td>
                                    <td>
                                        <p>{{ row.meaning }}</p>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0)" @click="showModal(row)"><i class="ti-pencil font-xs text-grey-500 mr-4"></i></a>
                                        <a href="javascript:void(0)" @click="deleteAlert(row.id)"><i class="ti-trash font-xs text-grey-500"></i></a>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="8">Nothing to see here</td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                    <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                </div>
            </div>
        </div>
        <div v-else class="col-12 text-center py-3">
            <div class="my-3">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <modal name="edit-modal"
            :width="'50%'"
            :height="'auto'"
            @before-close="beforeClose">
            <div class="container-fluid">
                <div class="row mb-0 mt-2 py-2">
                    <div class="col-12 px-4 py-0">
                        <a @click="closeModal()" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                        <h3>Edit Level Overview</h3>
                        <hr>
                    </div>
                </div>
                <form @submit.prevent="patchLevel()" class="row align-item-center pb-3">
                    <div class="form-group col-12">
                        <label for="">Level</label>
                        <input type="text" v-model="current.level" class="form-control" required>
                    </div>
                    <div class="form-group col-12">
                        <label for="">Final Remarks</label>
                        <input type="text" v-model="current.final_remarks" class="form-control" required>
                    </div>
                    <div class="form-group col-12">
                        <label for="">Meaning</label>
                        <input type="text" v-model="current.meaning" class="form-control" required>
                    </div>
                    <div class="col-12 text-right">
                        <button class="btn btn-lg btn-current">Submit</button>
                    </div>
                </form>
            </div>
        </modal>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            isLoad: true,
            data: [],
            current: {},
            page: 1,
            form: {
              level: '',
              final_remarks: '',
              meaning: '',
            },
            tableFilter: '',
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Level', field: 'level', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Final Remarks', field: 'final_remarks', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Meaning', field: 'meaning', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Action', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
            ]
        }
    },
    created(){
        this.getLevel()
    },
    methods:{
        async getLevel(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/level-overview`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
                this.isLoad = false
            })
        },
        async postLevel(){
            var data = {
                level : this.form.level,
                final_remarks : this.form.final_remarks,
                meaning : this.form.meaning,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/level-overview`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.$swal({
                        toast: true,
                        title: 'Level Overview',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.form.level = ''
                    this.form.final_remarks = ''
                    this.form.meaning = ''
                    this.getLevel()
                } else {
                    this.$swal({
                        toast: true,
                        title: "Level Overview",
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch((err) => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: "Level Overview",
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async patchLevel(){
            var data = {
                _method : 'patch',
                id : this.current.id,
                level : this.current.level,
                final_remarks : this.current.final_remarks,
                meaning : this.current.meaning,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/level-overview`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.$swal({
                        toast: true,
                        title: 'Level Overview',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.closeModal()
                } else {
                    this.$swal({
                        toast: true,
                        title: "Level Overview",
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch((err) => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: "Level Overview",
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        showModal(item){
            this.current = item
            this.$modal.show('edit-modal')
        },
        closeModal(){
            this.$modal.hide('edit-modal')
        },
        beforeClose() {
            this.current = {}
            this.getLevel()
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deleteLevel(id)
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Level Overview',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteLevel(id){
            var data = {
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/level-overview`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.getLevel()
                return res
            }).catch(err => {
                return err
            })
        },
    },
}
</script>