<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div class="row justify-content-end">
                <div class="col-lg-6 mb-3">
                    <router-link :to="{name: 'SettingsTestProgramDateCreateAdmin'}" class="btn btn-current btn-lg fw-500"><i class="fas fa-plus mr-2"></i>Date</router-link>
                </div>
                <div class="col-lg-6 mb-3">
                    <input type="text" class="form-control" v-model="tableFilter" placeholder="Search..."/>
                </div>
                <div class="col-12">
                    <div class="table-responsive mw-100 px-1">
                        <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="data" :page="page" :perPage="10">
                            <template name="default" slot-scope="{ row, index }">
                                <tr v-if="row" class="text-center">
                                    <td>{{ index+1 }}</td>
                                    <td>{{ formatDate(row.reg_date[0]) }} - {{ formatDate(row.reg_date[1]) }}</td>
                                    <td>{{ formatDate(row.test_date) }}</td>
                                    <td>{{ row.category_test }}</td>
                                    <td>
                                        <a @click="changeActive(row.id)" href="javascript:void(0)" v-if="row.main == 1" class="btn btn-success"><i class="fas fa-check"></i></a>
                                        <a @click="changeActive(row.id)" href="javascript:void(0)" v-if="row.main == 0" class="btn btn-danger"><i class="fas fa-times"></i></a>
                                    </td>
                                    <td>
                                        <router-link :to="{name: 'SettingsTestProgramDateEditAdmin', params: {idRegisterDate: row.id}}"><i class="ti-pencil font-xs text-grey-500 mr-4"></i></router-link>
                                        <a href="javascript:void(0)" @click="deleteAlert(row.id)"><i class="ti-trash font-xs text-grey-500"></i></a>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="8">Nothing to see here</td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                    <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                </div>
            </div>
        </div>
        <div v-else class="col-12 text-center py-3">
            <div class="my-3">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
export default {
    data(){
        return{
            media : process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            data: [],
            page: 1,
            tableFilter: '',
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Tanggal Pendaftaran', field: 'reg_date', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Tanggal Tes', field: 'test_date', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Program', field: 'category_test', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Status', field: 'status', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Action', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
            ]
        }
    },
    created(){
        this.getTestProgramDate()
    },
    methods:{
        formatDate(date) {
            return moment(date).format('MMMM DD, YYYY');
        },
        async getTestProgramDate(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/test-program-date`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
                this.isLoad = false
            })
        },
        async changeActive(id) {
            var data = {
                id: id,
                _method: 'patch'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/test-program-date/set-main`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.getTestProgramDate()
                this.$swal({
                    toast: true,
                    title: 'Test Program Date',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch( () => {
                this.$swal({
                    toast: true,
                    title: 'Test Program Date',
                    text: 'Failed Change Test Program Date Main!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deleteContent(id)
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Test Program Date',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteContent(id){
            var data = {
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/test-program-date`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.getTestProgramDate()
                return res
            }).catch(err => {
                return err
            })
        },
    },
}
</script>
