<template>
  <div class="row">
    <div class="col-12" v-if="!isLoad">
      <div class="row">
          <div class="col-12">
              <form @submit.prevent="postData" class="row justify-content-center">
                  <div class="form-group col-12">
                      <label for="">File</label>
                      <select class="form-control" v-model="how.type">
                          <option value="1">URL</option>
                          <option value="2">Upload File</option>
                      </select>
                  </div>
                  <div v-if="how.type == 1" class="form-group col-12">
                      <label for="">Input URL</label>
                      <input type="url" v-model="how.path" placeholder="Insert URL" class="form-control">
                  </div>
                  <div v-else-if="how.type == 2" class="form-group col-12">
                      <label for="">Upload File </label><br>
                      <file-upload
                      class="btn btn-lg btn-current mr-1"
                      @input="updateFile"
                      @input-file="inputFile"
                      @input-filter="fileFilter"
                      :custom-action="fileAction"
                      accept="video/mp4"
                      ref="how" required>
                      <i class="fa fa-plus"></i>
                      Upload Video
                      </file-upload>
    
                      <a v-if="how.path" :href="how.type == 2 ?  how.path : how.path" target="_blank" class="btn btn-lg btn-success">Current File</a>
    
                      <button type="button" class="btn btn-lg btn-danger ml-1"  v-if="!$refs.how || $refs.how.active" @click.prevent="$refs.how.active = false">
                      <i class="fa fa-stop" aria-hidden="true"></i>
                      Stop Upload
                      </button>
                  </div>
                  <div class="form-group col-12 text-right">
                      <button type="submit" class="btn btn-current">Submit</button>
                  </div>
              </form>
          </div>
          <div class="col-12" v-if="current.id">
            <hr>
            <video width="100%" controls>
              <source :src="current.type == 1 ? current.path :  current.path" type="video/mp4">
            </video>
          </div>
      </div>
    </div>
    <div class="col-12" v-else>
      <div class="row">
        <div class="col-12 text-center py-3">
          <div class="my-3">
            <div class="spinner-grow text-warning mx-3" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-warning mx-3" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-warning mx-3" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-warning mx-3" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-warning mx-3" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-warning mx-3" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import FileUpload from 'vue-upload-component'
export default {
  components:{
      FileUpload
  },
  data(){
      return{
          media: process.env.VUE_APP_URL_CLOUD,
          isLoad: true,
          current: {
              id: '',
              type: 1,
              path: '',
          },
          files: [],
          how: {
              id: '',
              type: 1,
              path: '',
          }
      }
  },
  created(){
      this.getHowToUse()
  },
  methods: {
      async getHowToUse(){
          await axios.get(`${process.env.VUE_APP_URL_API}/admin/how-to-use`, {
              headers: {
                  Authorization: `Bearer ${localStorage.token}`
              }
          }).then(res => {
              if(res.data.data) {
                  this.how = res.data.data
                  this.current = this.how
              }
              this.isLoad = false
          })
      },
      async postData(){
          var data = {
              type : this.how.type,
              path : this.how.path,
          }
          await axios.post(`${process.env.VUE_APP_URL_API}/admin/how-to-use/create-or-update`, data, {
              headers: {
                  Authorization: `Bearer ${localStorage.token}`
              }
          }).then(res => {
              this.getHowToUse()
              if(res.data.success) {
                  this.$swal({
                      toast: true,
                      title: 'How To Use',
                      text: res.data.message,
                      icon: 'success',
                      position: 'top-end',
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                  })
              } else {
                  this.$swal({
                      toast: true,
                      title: 'How To Use',
                      text: res.data.message,
                      icon: 'error',
                      position: 'top-end',
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                  })
              }
          })
      },
      updateFile(value) {
          this.files = value
      },
      async fileAction(file) {
          return this.postFile(file)
      },
      async inputFile(newFile, oldFile) {
          if (!newFile && oldFile) {
              if (oldFile.success && oldFile.response.path) {
                  // this.deleteFile(oldFile)
              }
          }
          if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
              if (!this.$refs.how.active) {
                  this.$refs.how.active = true
              }
          }
      },
      fileFilter(newFile, oldFile, prevent) {
          if (newFile && !oldFile) {
              if (!/\.(mp4)$/i.test(newFile.name)) {
                  return prevent()
              }
          }
      },
      async postFile(file){
          let data = new FormData();
          data.append('dir',  'video');
          data.append('attachment', file.file);
          data.append('_token', 'elingwaysecretkey');
          await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
              headers: {
                  'Accept': 'application/json',
                  'content-type': 'multipart/form-data',
                  'Authorization': `Bearer ${localStorage.token}`
              },
          }).then(res => {
              file.response = res.data
              this.how.path = res.data.path
              return res.data
          })
      },
      async deleteFile(file){
          let data = new FormData();
          data.append('path', file.response.path);
          data.append('_token', 'elingwaysecretkey');
          await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
              headers: {
                  'Accept': 'application/json',
                  'content-type': 'multipart/form-data',
                  'Authorization': `Bearer ${localStorage.token}`
              },
          }).then(res => {
              this.files = []
              this.how.path = ''
              return res.data
          })
      },
  }
  
}
</script>