<template>
    <div class="row">
        <div v-if="!isLoad" class="col-12">
            <form @submit.prevent="patchTnc()">
                <div class="form-group">
                    <label for="">Privacy Policy</label>
                    <CustomCkEditor :ck-placeholder="'Enter your Terms Condition'" :ck-value="form.privacy_policy" @ck-on-editor-input="setCondition($event)"></CustomCkEditor>
                </div>
                <div class="form-group">
                    <label for="">Terms Condition</label>
                    <CustomCkEditor :ck-placeholder="'Enter your Privacy Policy'" :ck-value="form.terms_condition" @ck-on-editor-input="setPolicy($event)"></CustomCkEditor>
                </div>
                <div class="form-group text-right">
                    <button class="btn btn-current">Submit</button>
                </div>
            </form>
        </div>
        <div class="col-12 text-center" v-else>
            <div class="my-3">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import CustomCkEditor from '../../../../../components/helpers/CustomCkEditor.vue'
export default {
    data() {
        return {
            isLoad: true,
            form: {
                id: '',
                terms_condition: '',
                privacy_policy: ''
            }
        }
    },
    components: {
        CustomCkEditor,
    },
    created() {
        this.getTnc()
    },
    methods: {
        setCondition(val) {
            this.form.terms_condition = val
        },
        setPolicy(val) {
            this.form.privacy_policy = val
        },
        async getTnc(){
            await axios.get(`${process.env.VUE_APP_URL_API}/terms-condition-privacy-policy`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.form = res.data.data
                }
            }).catch((err) => console.error(err)).finally(() => this.isLoad = false)
        },
        async patchTnc(){
            let data = new FormData();
            data.append('id', this.form.id);
            data.append('terms_condition', this.form.terms_condition);
            data.append('privacy_policy', this.form.privacy_policy);
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/terms-condition-privacy-policy/create-or-update`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                        toast: true,
                        title: 'Terms of Conditions',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                })
                this.getTnc()
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Terms of Conditions',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
    
}
</script>