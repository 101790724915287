<template>
    <div class="row pb-5">
        <div class="col-12">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <splide :options="slide" @splide:move="checkSplide" @splide:scroll="checkSplide"
                        @splide:scrolled="checkSplide" @splide:dragged="checkSplide" @splide:dragging="checkSplide"
                        @splide:drag="checkSplide">
                        <splide-slide class="w-auto m-0"></splide-slide>
                        <splide-slide class="text-center h-auto ml-1">
                            <router-link :to="{ name: 'SettingsAdmin' }">
                                <div style="background-image: url(/images/started.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;"
                                    class="card p-4 p-md--5 pb-5 text-left border-0 shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Getting
                                            Started</h1>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{ name: 'SettingsMasterAdmin' }">
                                <div style="background-image: url(/images/started.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;"
                                    class="card p-4 p-md--5 pb-5 text-left border-0 shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Master Data
                                        </h1>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{ name: 'SettingsTestProgramDateAdmin' }">
                                <div style="background-image: url(/images/started.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;"
                                    class="card p-4 p-md--5 pb-5 text-left border-0 shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Test Program
                                        </h1>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{ name: 'SettingsAboutAdmin' }">
                                <div style="background-image: url(/images/started.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;"
                                    class="card p-4 p-md--5 pb-5 text-left border-0 shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">About Us</h1>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{ name: 'SettingsTncAdmin' }">
                                <div style="background-image: url(/images/started.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;"
                                    class="card p-4 p-md--5 pb-5 text-left border-0 shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Terms of
                                            Condition</h1>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{ name: 'SettingsTestimonyAdmin' }">
                                <div style="background-image: url(/images/started.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;"
                                    class="card p-4 p-md--5 pb-5 text-left border-0 shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Testimony</h1>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto">
                            <router-link :to="{ name: 'SettingsPaymentMethodAdmin' }">
                                <div style="background-image: url(/images/started.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;"
                                    class="card p-4 p-md--5 pb-5 text-left border-0 shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Payment
                                            Methods</h1>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="text-center h-auto mr-1">
                            <router-link :to="{ name: 'SettingsPartnerAdmin' }">
                                <div style="background-image: url(/images/started.png); background-position: center; background-size: auto 80%; background-repeat: no-repeat;"
                                    class="card p-4 p-md--5 pb-5 text-left border-0 shadow rounded-lg mb-5 h250 ">
                                    <div class="card-body py-4">
                                        <h1 class="text-grey-900 fw-700 display-1 text-center mt-0 mb-5 pt-1">Partners</h1>
                                    </div>
                                </div>
                            </router-link>
                        </splide-slide>
                        <splide-slide class="w-auto m-0"></splide-slide>
                    </splide>
                </div>
            </div>
            <div class="row mb-3" v-if="isShow">
                <div class="col-12">
                    <div class="row" v-if="tab == 'SettingsAdmin'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-2 pb-0">
                                    <h2 class="fw-400 font-md d-block"><b>Getting Started</b></h2>
                                    <hr>
                                    <div>
                                        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                                            <ul id="pills-tab" role="tablist"
                                                class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '' }">
                                                    <router-link :to="{ name: 'SettingsAdmin' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '' }">WA</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#CEFR' }">
                                                    <router-link :to="{ name: 'SettingsAdmin', hash: '#CEFR' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#CEFR' }">CEFR</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#TYE' }">
                                                    <router-link :to="{ name: 'SettingsAdmin', hash: '#TYE' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#TYE' }">TYE</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#VT' }">
                                                    <router-link :to="{ name: 'SettingsAdmin', hash: '#VT' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#VT' }">VT</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#SLA' }">
                                                    <router-link :to="{ name: 'SettingsAdmin', hash: '#SLA' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#SLA' }">SLA</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#MLA' }">
                                                    <router-link :to="{ name: 'SettingsAdmin', hash: '#MLA' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#MLA' }">MLA</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-content">
                                            <div class="tab-pane fade show active" v-if="tab_current == ''">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>WIN Analysis</b></h2>
                                                            <WA />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#CEFR'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Common European Framework
                                                                    of Reference for Language</b></h2>
                                                            <CEFR />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#TYE'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Test Your English</b></h2>
                                                            <PT />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#VT'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>VARK Test</b></h2>
                                                            <VARK />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#SLA'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>5 Stages of Second
                                                                    Language Acquisition</b></h2>
                                                            <SLA />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#MLA'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Monitoring for Language
                                                                    Assessment on Distance Foreign Language Learning</b>
                                                            </h2>
                                                            <PEP />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab == 'SettingsMasterAdmin'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-2 pb-0">
                                    <h2 class="fw-400 font-md d-block"><b>Master Data</b></h2>
                                    <hr>
                                    <div>
                                        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                                            <ul id="pills-tab" role="tablist"
                                                class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '' }">
                                                    <router-link :to="{ name: 'SettingsMasterAdmin' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '' }">CEFR Mapping</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#MO' }">
                                                    <router-link :to="{ name: 'SettingsMasterAdmin', hash: '#MO' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#MO' }">Mapping
                                                        Objective</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#LO' }">
                                                    <router-link :to="{ name: 'SettingsMasterAdmin', hash: '#LO' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#LO' }">Level
                                                        Overview</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#HS' }">
                                                    <router-link :to="{ name: 'SettingsMasterAdmin', hash: '#HS' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#HS' }">Home Slider</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-content">
                                            <div class="tab-pane fade show active" v-if="tab_current == ''">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>CEFR Mapping</b></h2>
                                                            <Grammar />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#MO'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Mapping Objective</b></h2>
                                                            <MappingObjective />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#LO'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Level Overview</b></h2>
                                                            <LevelOverview />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#HS'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Home Slider</b></h2>
                                                            <Slider />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab == 'SettingsTestProgramDateAdmin'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-2 pb-0">
                                    <h2 class="fw-400 font-md d-block"><b>Test Program Date</b></h2>
                                    <hr>
                                    <TestProgramDate/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="tab == 'SettingsAboutAdmin'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-2 pb-0">
                                    <h2 class="fw-400 font-md d-block"><b>About Us</b></h2>
                                    <hr>
                                    <div>
                                        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                                            <ul id="pills-tab" role="tablist"
                                                class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '' }">
                                                    <router-link :to="{ name: 'SettingsAboutAdmin' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '' }">Content</router-link>
                                                </li>
                                                <li class="list-inline-item"
                                                    :class="{ 'active': tab_current == '#PROGRAM' }">
                                                    <router-link :to="{ name: 'SettingsAboutAdmin', hash: '#PROGRAM' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#PROGRAM' }">Program</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#FAQ' }">
                                                    <router-link :to="{ name: 'SettingsAboutAdmin', hash: '#FAQ' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#FAQ' }">FAQ</router-link>
                                                </li>
                                                <li class="list-inline-item"
                                                    :class="{ 'active': tab_current == '#MILESTONES' }">
                                                    <router-link :to="{ name: 'SettingsAboutAdmin', hash: '#MILESTONES' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#MILESTONES' }">Milestones</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#FREE' }">
                                                    <router-link :to="{ name: 'SettingsAboutAdmin', hash: '#FREE' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#FREE' }">Free Trial
                                                        Course</router-link>
                                                </li>
                                                <li class="list-inline-item" :class="{ 'active': tab_current == '#HOW' }">
                                                    <router-link :to="{ name: 'SettingsAboutAdmin', hash: '#HOW' }"
                                                        class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                                                        :class="{ 'active': tab_current == '#HOW' }">How To Use</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-content">
                                            <div class="tab-pane fade show active" v-if="tab_current == ''">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Content</b></h2>
                                                            <Content />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#PROGRAM'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Program</b></h2>
                                                            <Program />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#FAQ'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>FAQ</b></h2>
                                                            <FAQ />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#MILESTONES'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Milestones</b></h2>
                                                            <Milestone />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#FREE'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>Free Trial Course</b></h2>
                                                            <Trial />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade show active" v-if="tab_current == '#HOW'">
                                                <div
                                                    class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                                                    <div class="row justify-content-around">
                                                        <div
                                                            class="card-body mb-3 pb-0 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                                            <h2 class="fw-400 font-lg d-block"><b>How To Use</b></h2>
                                                            <HowToUse />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab == 'SettingsTncAdmin'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-2 pb-0">
                                    <h2 class="fw-400 font-md d-block"><b>Terms of Condition</b></h2>
                                    <hr>
                                    <Tnc />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab == 'SettingsTestimonyAdmin'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-2 pb-0">
                                    <h2 class="fw-400 font-md d-block"><b>Testimony</b></h2>
                                    <hr>
                                    <Testimony />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab == 'SettingsPaymentMethodAdmin'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-2 pb-0">
                                    <h2 class="fw-400 font-md d-block"><b>Payment Methods</b></h2>
                                    <hr>
                                    <PaymentMethod />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab == 'SettingsPartnerAdmin'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body mb-2 pb-0">
                                    <h2 class="fw-400 font-md d-block"><b>Partner</b></h2>
                                    <hr>
                                    <Partner />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CEFR from './Sub/CEFR.vue'
import SLA from './Sub/SLA.vue'
import PT from './Sub/PT.vue'
import WA from './Sub/WA.vue'
import VARK from './Sub/VARK.vue'
import PEP from './Sub/PEP.vue'
import Grammar from './Sub/GrammarPoint/Grammar.vue'
import MappingObjective from './Sub/MappingObjective/MappingObjective.vue'
import LevelOverview from './Sub/LevelOverview/LevelOverview.vue'
import Slider from './Sub/Slider/Slider.vue'
import Content from './Sub/About/Content/Content.vue'
import Program from './Sub/About/Program/Program.vue'
import Tnc from './Sub/Tnc/Tnc.vue'
import FAQ from './Sub/About/FAQ/FAQ.vue'
import Milestone from './Sub/About/Milestone/Milestone.vue'
import Testimony from './Sub/Testimony/Testimony.vue'
import PaymentMethod from './Sub/PaymentMethod/PaymentMethod.vue'
import Partner from './Sub/Partner/Partner.vue'
import Trial from './Sub/Trial/Trial.vue'
import HowToUse from './Sub/HowToUse/HowToUse.vue'
import TestProgramDate from './Sub/TestProgramDate/TestProgramDate.vue'
export default {
    components: {
        CEFR,
        SLA,
        PT,
        WA,
        VARK,
        PEP,
        Grammar,
        MappingObjective,
        LevelOverview,
        Slider,
        Content,
        Program,
        FAQ,
        Milestone,
        Tnc,
        Testimony,
        Trial,
        PaymentMethod,
        Partner,
        HowToUse,
        TestProgramDate,
    },
    name: "Settings",
    data() {
        return {
            user: {},
            tab: this.$route.name,
            tab_current: this.$route.hash,
            isShow: true,
            slide: {
                rewind: false,
                perPage: 2,
                gap: '2rem',
                autoplay: false,
                arrows: true,
                pagination: false,
                breakpoints: {
                    '800': {
                        perPage: 1,
                        gap: '2rem'
                    },
                    '640': {
                        perPage: 1,
                        gap: '2rem'
                    },
                    '480': {
                        perPage: 1,
                        gap: '2rem'
                    },
                }
            },
        }
    },
    mounted() {
        this.checkSplide()
    },
    async created() {
        await axios.get(`${process.env.VUE_APP_URL_API}/user`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            this.user = res.data
        }).catch((err) => console.error(err))
    },
    methods: {
        checkSplide() {
            setTimeout(() => {
                let el = document.querySelectorAll('.splide__list .splide__slide')
                if (el) {
                    let length = el.length
                    let lastindex = length - 2;
                    let showPrev = el[1].classList.contains('is-visible')
                    let showNext = el[lastindex].classList.contains('is-visible')
                    if (showPrev) {
                        let prev = document.querySelector('.splide__arrow.splide__arrow--prev')
                        if (prev) {
                            prev.style.visibility = 'hidden'
                        }
                    } else {
                        let prev = document.querySelector('.splide__arrow.splide__arrow--prev')
                        if (prev) {
                            prev.style.visibility = 'inherit'
                        }
                    }
                    if (showNext) {
                        let next = document.querySelector('.splide__arrow.splide__arrow--next')
                        if (next) {
                            next.style.visibility = 'hidden'
                        }
                    } else {
                        let next = document.querySelector('.splide__arrow.splide__arrow--next')
                        if (next) {
                            next.style.visibility = 'inherit'
                        }
                    }
                }
            }, 500)

        },
        showTab(tab) {
            this.isShow = false
            this.tab = tab
            this.isShow = true
        },
        changeTab(tab) {
            this.tab_current = tab
        }
    }
}
</script>
