<template>
    <div class="row">
        <div v-if="!isLoad" class="col-12">
            <form @submit.prevent="patchFreeTrialCourse()">
                <div class="form-group">
                    <label for="">Course</label>
                    <select @change="setCurrent()" class="form-control" v-model="form.course_id" required>
                        <option value="" disabled selected>Select Course</option>
                        <option :value="item.id" v-for="(item, index) in courses" :key="index">{{ item.name }}</option>
                    </select>
                    <div class="row mt-3" v-if="current">
                        <div class="col-lg-4 mb-3">
                            <img width="100%" :src=" current.banner" class="img-thumbnail rounded" alt="">
                        </div>
                        <div class="col-lg-8 pt-2">
                            <p class="font-lg text-current fw-600 mb-1">{{ current.name }}</p>
                            <span class="font-xs text-black fw-400">{{ current.description }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group text-right">
                    <button class="btn btn-current">Submit</button>
                </div>
            </form>
        </div>
        <div class="col-12 text-center" v-else>
            <div class="my-3">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            form: {
                id: '',
                course_id: '',
            },
            courses: [],
            current: null
        }
    },
    created() {
        this.getCourses()
    },
    methods: {
        setCurrent() {
            if(this.courses.length) {
                let course = this.courses.find(x => x.id == this.form.course_id)
                this.current = course
            }
        },
        async getCourses(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/simple-course-publish`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.courses = res.data.data
                    this.getFreeTrial()
                }
            }).catch((err) => console.error(err)).finally(() => this.isLoad = false)
        },
        async getFreeTrial(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/free-course-trial`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.form = res.data.data
                    this.setCurrent()
                }
            }).catch((err) => console.error(err)).finally(() => this.isLoad = false)
        },
        async patchFreeTrialCourse(){
            let data = new FormData();
            data.append('id', this.form.id);
            data.append('course_id', this.form.course_id);
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/free-course-trial/create-or-update`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                        toast: true,
                        title: 'Free Trial Course',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                })
                this.getFreeTrial()
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Free Trial Course',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
    
}
</script>